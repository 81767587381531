import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { UiDescriptor } from "./MaintenanceView";
import { Grid, TextField } from "@mui/material";

import { GenericFieldInterface } from "./GenericFieldInterface";
import * as React from "react";
import { setHasError } from "../GenericUIViews/GenericMaintenanceList/GenericMaintenanceListState";
import { AppDispatch } from "../../../store";
import { useDispatch } from "react-redux";
import { useTranslation } from "../custom-hooks/useTranslation";

interface GenericTextEditFieldInterface extends GenericFieldInterface {
  type: React.InputHTMLAttributes<unknown>["type"];
}

const DELAY_BEFORE_UPDATE = 10; //10Ms
export const GenericTextEditField = (props: GenericTextEditFieldInterface) => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(true);
  const [timer, setTimer] = useState<any>();
  const [localValue, setLocalValue] = useState<any>(undefined);

  const ref = useRef(localValue);

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    setLocalValue(props.value || "");
  }, [props.value]);

  useEffect(() => {
    if (props.uiDescriptor.validator) {
      const regex = new RegExp(props.uiDescriptor.validator);
      if (props.value) {
        const testResult = regex.test(props.value);
        dispatch(setHasError(!testResult));
        setIsValid(testResult);
      }
    }
  }, [props.value, props.uiDescriptor.validator]);

  const getLocalValue = () => ref.current;
  return (
    <Grid item xs={props.uiDescriptor.size || 4}>
      <TextField
        fullWidth
        required={props.required}
        id={props.fieldKey}
        label={t(`${props.translationPrefix}.${props.fieldKey}`)}
        value={props.value || (props.type == "time" ? "00:00" : "")}
        disabled={props.disabled}
        type={props.type}
        error={!isValid}
        onChange={(e) => {
          setLocalValue(e.currentTarget.value);
          ref.current = e.currentTarget.value;
          if (timer) {
            clearTimeout(timer);
          }

          setTimer(
            setTimeout(() => {
              if (props.onChange) {
                props.onChange({
                  //@ts-ignore
                  currentTarget: { value: getLocalValue() },
                });
              }
            }, DELAY_BEFORE_UPDATE)
          );
        }}
      />
    </Grid>
  );
};
