import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  upsertTranslationToFirebase,
  fetchAllTranslatonsFromFirebase,
  fetchAllTranslationsFromCache,
} from "./translationFirebase";

const staticTranslationDE = require('./de.json');
const staticTranslationEN = require('./en.json');

export interface Translations {
  language: string;
  translations: any;
  [key: string]: string | undefined;
}

export interface MissingKey {
  language: string;
  key: string;
}

export interface TranslationKeyValuePairs {
  [key: string]: string;
}
export interface LanguageMap {
  [language: string]: TranslationKeyValuePairs;
}

export interface TranslationsState {
  initialized: boolean;
  translations: Translations[];
  language: string;
  translationsFlat: LanguageMap;
}

export const transformObjToFlatJSON = (
  obj: any = {},
  res: any = {},
  extraKey: string = ""
) => {
  for (let key in obj) {
    if (typeof obj[key] !== "object") {
      res[extraKey + key] = obj[key];
    } else {
      transformObjToFlatJSON(obj[key], res, `${extraKey}${key}.`);
    }
  }
  return res;
};

const buildStaticTranslations = () => {
  const flatTranslationsDE = transformObjToFlatJSON(staticTranslationDE);
  const keyMapDE: TranslationKeyValuePairs = {};
  for (let key of Object.keys(flatTranslationsDE)) {
    keyMapDE[key] = flatTranslationsDE[key];
  }

  const flatTranslationsEN = transformObjToFlatJSON(staticTranslationEN);
  const keyMapEN: TranslationKeyValuePairs = {};
  for (let key of Object.keys(flatTranslationsEN)) {
    keyMapEN[key] = flatTranslationsEN[key];
  }

  return {de : keyMapDE, en: keyMapEN}
}

const initialState : TranslationsState = {
  initialized: false,
  translations: [{language: "de", translations: staticTranslationDE},{language: "en", translations: staticTranslationEN}],
  language : "de",
  translationsFlat: buildStaticTranslations()
}

const fetchAllTranslations = createAsyncThunk(
  "translations/fetchallTranslations",
  async () => {
    const response = await fetchAllTranslatonsFromFirebase();

    return response;
  }
);

const fetchAllTranslationsCache = createAsyncThunk(
  "translations/fetchallTranslationsCache",
  async () => {
    const response = await fetchAllTranslationsFromCache();

    return response;
  }
);

export const translationSlice = createSlice({
  name: "translations",
  initialState: initialState,
  reducers: {
    addMissingTranslation: (state, action: PayloadAction<MissingKey>) => {
      let path = action.payload.key.split(".");

      let refIndex = state.translations.findIndex(
        (value) => value.language === action.payload.language
      );

      let ref = state.translations[refIndex] || {};
      let translations = { ...ref?.translations } || {};

      let nodes = translations;

      //travese the json until we reach the bottom reference
      path.forEach((node, index) => {
        const nodeValue = nodes[node];
        if (nodeValue && typeof nodeValue !== "string") {
          nodes = nodeValue;
        }

        if (typeof nodeValue === "string") {
          nodes[node] = {};
        }

        if (!nodeValue) {
          nodes[node] = {};
        }
      });

      nodes[path[path.length - 1]] = action.payload.key;

      if (state.initialized) {
        if (refIndex < 0) {
          state.translations.push({
            language: action.payload.language,
            translations: translations,
          });
          state.translationsFlat[action.payload.language] = {};
          state.translationsFlat[action.payload.language][action.payload.key] =
            action.payload.key;
        } else {
          state.translations[refIndex].translations = translations;
          state.translationsFlat[action.payload.language][action.payload.key] =
            action.payload.key;
        }

        upsertTranslationToFirebase({
          language: action.payload.language,
          translations: translations,
        }).then(() => {
          console.info(
            `Added translation Entry for Language ${action.payload.language} and key ${action.payload.key}`
          );
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTranslations.fulfilled, (state, action) => {
        state.translations = action.payload;
        action.payload.forEach((value, index) => {
          const flatTranslations = transformObjToFlatJSON(value.translations);
          const keyMap: TranslationKeyValuePairs = {};
          for (let key of Object.keys(flatTranslations)) {
            keyMap[key] = flatTranslations[key];
          }
          state.translationsFlat[value.language] = {...state.translationsFlat[value.language], ...keyMap}
        })

        state.initialized = true;
      })
      .addCase(fetchAllTranslations.pending, (state, action) => {
        state.translations = [];
        state.initialized = true;
      })
      .addCase(fetchAllTranslations.rejected, (state, action) => {
        state.translations = [];
        state.initialized = true;
      })
      .addCase(fetchAllTranslationsCache.fulfilled, (state, action) => {
        state.translations = action.payload;
        action.payload.forEach((value, index) => {
          const flatTranslations = transformObjToFlatJSON(value.translations);
          const keyMap: TranslationKeyValuePairs = {};
          for (let key of Object.keys(flatTranslations)) {
            keyMap[key] = flatTranslations[key];
          }
          state.translationsFlat[value.language] = {...state.translationsFlat[value.language], ...keyMap}
        });
      })
      .addCase(fetchAllTranslationsCache.pending, (state, action) => {
        state.translations = [];
      })
      .addCase(fetchAllTranslationsCache.rejected, (state, action) => {
        state.translations = [];
      });
  },
});

export const { addMissingTranslation } = translationSlice.actions;
export { fetchAllTranslations, fetchAllTranslationsCache };
export const translationReducer = translationSlice.reducer;
