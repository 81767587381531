import {
  AsyncThunk,
  createAsyncThunk,
  createSlice,
  Draft,
  PayloadAction,
  Slice,
} from "@reduxjs/toolkit";
import { GenericFirebaseFields } from "./BaseFireBaseServices";
import { AnyAction } from "redux";
import { Reducer } from "react";

export interface DynamicCrudReducerExport<ItemInterface, StateInterface> {
  slice: Slice<
    StateInterface,
    {
      add: (state: any, action: PayloadAction<ItemInterface>) => void;
      update: (state: any, action: PayloadAction<ItemInterface>) => void;
      delete: (state: any, action: PayloadAction<ItemInterface>) => void;
      setMultiple: (state: any, action: PayloadAction<ItemInterface[]>) => void;
    }
  >;
  reducer: Reducer<StateInterface, AnyAction>;
  fetchAll: AsyncThunk<ItemInterface[], void, {}>;
}

export const getDynamicCRUDReducer = <
  ItemInterface extends GenericFirebaseFields,
  StateInterface extends { items: ItemInterface[]; initialized: boolean }
>(
  name: string,
  initialState: StateInterface,
  readCachedItems: () => Promise<ItemInterface[]>
) => {
  const prefix = name + "/";
  const fetchAll = createAsyncThunk(prefix + "fetchAllCached", async () => {
    const response = await readCachedItems();
    return response;

    return [] as ItemInterface[];
  });

  const slice = createSlice({
    name: name,
    initialState,
    reducers: {
      add: (state, action: PayloadAction<ItemInterface>) => {
        let index = state.items.findIndex(
          (value) => value.id === action.payload.id
        );
        if (index > -1) {
          // @ts-ignore
          state.items.splice(index, 1, action.payload);
        } else {
          //@ts-ignore
          state.items.push(action.payload);
        }
      },

      delete: (state, action: PayloadAction<ItemInterface>) => {
        state.items = state.items.filter(
          (item) => !(item.id === action.payload.id)
        );
      },

      update: (state, action: PayloadAction<ItemInterface>) => {
        state.items = state.items.map((value) => {
          if (value.id === action.payload.id) {
            return action.payload as Draft<ItemInterface>;
          } else {
            return value;
          }
        });
      },

      setMultiple: (state, action: PayloadAction<ItemInterface[]>) => {
        state.items = action.payload as Draft<ItemInterface[]>;
      },
    },

    extraReducers: (builder) => {
      builder
        .addCase(fetchAll.fulfilled, (state, action) => {
          state.items = action.payload as Draft<ItemInterface[]>;
          state.initialized = true;
        })
        .addCase(fetchAll.pending, (state, action) => {
          state.items = [];
          //state.initialized = true;
        });
    },
  });

  const reducer = slice.reducer as Reducer<StateInterface, AnyAction>;

  return {
    reducer: reducer,
    fetchAll: fetchAll,
    slice: slice,
  } as DynamicCrudReducerExport<ItemInterface, StateInterface>;
};
