import { useMap, useMapEvents } from "react-leaflet";
import React, { useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { LatLngBounds, Marker } from "leaflet";
import {
  updateLoraNodesInViewPort,
  updateSelectedElement,
  updateViewPort,
  updateZoomLevel,
  ViewPort,
} from "./mapState";
import { addLamp, Lamp } from "../../model/lamps/lampState";
import * as L from "leaflet";
import "leaflet.markercluster";
import { isPointInPolygon, getDistance } from "geolib";
import { RootState, store } from "../../store";
import { addMetersToGeoPoint } from "../../helper/geoPoint";
import HubIcon from "@mui/icons-material/Hub";
import { LoraNode } from "../../model/lora-node/LoraNode";
import loraNodeState from "../../model/lora-node/loraNodeState";
import { Divider, Grid, Typography } from "@mui/material";
import ReactDOM from "react-dom";
import { NodeDetails } from "../nodeDetails/NodeDetails";
import { useNavigate } from "react-router-dom";
import { SnackbarProvider, useSnackbar } from "notistack";

const getBoundsAsRectangle = (bounds: LatLngBounds) => {
  let NE = bounds.getNorthEast();
  let SW = bounds.getSouthWest();
  let tr = { lat: NE.lat, lng: NE.lng };
  let bl = { lat: SW.lat, lng: SW.lng };
  let tl = { lat: NE.lat, lng: SW.lng };
  let br = { lat: SW.lat, lng: NE.lng };

  return {
    topLeft: tl,
    topRight: tr,
    bottomLeft: bl,
    bottomRight: br,
    center: { lat: bounds.getCenter().lat, lng: bounds.getCenter().lng },
  } as ViewPort;
};

const getViewportToRender = (viewPort: ViewPort) => {
  let result = { ...viewPort };

  let km = getDistance(viewPort.topLeft, viewPort.topRight);

  result.topLeft = addMetersToGeoPoint(
    viewPort.topLeft.lat,
    viewPort.topLeft.lng,
    km, // move latiude up
    -km // move Longitude to the left
  );

  result.topRight = addMetersToGeoPoint(
    viewPort.topRight.lat,
    viewPort.topRight.lng,
    km, // move latiude up
    km // move Longitude to the right
  );

  result.bottomRight = addMetersToGeoPoint(
    viewPort.bottomRight.lat,
    viewPort.bottomRight.lng,
    -km, // move latiude down
    km // move Longitude to the right
  );

  result.bottomLeft = addMetersToGeoPoint(
    viewPort.bottomLeft.lat,
    viewPort.bottomLeft.lng,
    -km, // move latiude down
    -km // move Longitude to the left
  );

  return result;
};

export default function MapHandler(props: { loraNodes: LoraNode[] }) {
  const map = useMap();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const markerClusterGroup = L.markerClusterGroup({
    chunkedLoading: true,
    showCoverageOnHover: true,
    zoomToBoundsOnClick: false,

    maxClusterRadius: (zoom) => {
      return (19 / zoom) * 20;
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  //let reduxNodes = useSelector((state: any) => state["loraNodes"]?.items);
  let reduxViewPort = useSelector((state: RootState) => state.map.viewPort);
  let selectedNode = useSelector(
    (state: RootState) => state.map.selectedElement
  );
  useEffect(() => {
    let viewPort = getViewportToRender(
      reduxViewPort || getBoundsAsRectangle(map.getBounds())
    );

    const visibleNodes: LoraNode[] = [];
    if (!props.loraNodes) {
      return;
    }
    for (let node of props.loraNodes) {
      if (node.location && viewPort) {
        if (
          isPointInPolygon({ lng: node.location.lng, lat: node.location.lat }, [
            viewPort.topLeft,
            viewPort.topRight,
            viewPort.bottomRight,
            viewPort.bottomLeft,
          ])
        ) {
          visibleNodes.push(node);
          let marker = L.marker(
            new L.LatLng(node.location.lat, node.location.lng),
            { icon: L.icon({ iconUrl: "/icons/hub.png", iconSize: [36, 36] }) }
          );
          marker.addEventListener({
            click: (event) => {
              dispatch(updateSelectedElement(node));
            },
          });

          marker.bindPopup(
            (layer) => {
              let div = document.createElement("div");
              div.style.minWidth = "500px";
              ReactDOM.render(
                <Grid container sx={{ minWidth: "500px" }} spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant={"h5"}
                      align={"center"}
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/loraNodes/${node.ttnPayload.ids.dev_eui}`)
                      }
                    >
                      {node.ttnPayload.ids.device_id}
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Provider store={store}>
                      <SnackbarProvider>
                        <NodeDetails
                          key={node.id}
                          id={node.id}
                          toolbar={{
                            showToolbar: true,
                            hardwareType: node?.hardwareType,
                            node: node,
                            enqueueSnackbar: enqueueSnackbar,
                          }}
                        />
                      </SnackbarProvider>
                    </Provider>
                  </Grid>
                </Grid>,
                div
              );

              return div;
            },
            { minWidth: 500 }
          );
          markerClusterGroup.addLayer(marker);
        }
      }
    }

    dispatch(updateLoraNodesInViewPort(visibleNodes));
    map.addLayer(markerClusterGroup);

    return () => {
      map.removeLayer(markerClusterGroup);
    };
  }, [props.loraNodes, reduxViewPort]);

  useEffect(() => {
    let viewPort = getViewportToRender(
      reduxViewPort || getBoundsAsRectangle(map.getBounds())
    );
    let markerList: Marker[] = [];
    if (selectedNode) {
      for (let lamp of selectedNode?.lamp) {
        if (lamp?.geoLocation?.lng && lamp?.geoLocation?.lat) {
          if (
            isPointInPolygon(
              { lng: lamp?.geoLocation?.lng, lat: lamp?.geoLocation?.lat },
              [
                viewPort.topLeft,
                viewPort.topRight,
                viewPort.bottomRight,
                viewPort.bottomLeft,
              ]
            )
          ) {
            let marker = L.marker(
              new L.LatLng(lamp.geoLocation.lat, lamp.geoLocation.lng),

              {
                icon: L.icon({
                  iconUrl: "/icons/bulb.png",
                  iconSize: [36, 36],
                }),
              }
            );
            markerList.push(marker);
            marker.bindPopup(lamp.name || "").addTo(map);
          }
        }
      }
      return () => {
        for (let marker of markerList) map.removeLayer(marker);
      };
    }
  }, [selectedNode]);
  const mapEvents = useMapEvents({
    moveend: (event) => {
      let bounds = getBoundsAsRectangle(map.getBounds());
      dispatch(updateViewPort(bounds));
    },
    zoomend: (event) => {
      dispatch(updateZoomLevel(map.getZoom()));
    },
    click: (event) => {
      dispatch(updateSelectedElement(null));
    },
  });

  return <div></div>;
}
