import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UiDefinition } from "./uiDefinition";
import { uiFirebaseServices } from "./uiFirebaseServices";

export interface UiDefinitionState {
  uiDefinitions: UiDefinition[];
}

const fetchAllCachedUIDefinitions = createAsyncThunk(
  "uiDefinitions/fetchAllCachedUIDefinitions",
  async () => {
    const response = await uiFirebaseServices.readCachedItems();
    return response;
  }
);

const initialState: UiDefinitionState = {
  uiDefinitions: [],
};

export const uiDefinitionsSlice = createSlice({
  name: "uiDefinitions",
  initialState,
  reducers: {
    addUIDefinition: (state, action: PayloadAction<UiDefinition>) => {
      let index = state.uiDefinitions.findIndex(
        (value) => value.id === action.payload.id
      );
      if (index > -1) {
        state.uiDefinitions.splice(index, 1, action.payload);
      } else {
        state.uiDefinitions.push(action.payload);
      }
    },
    addMultipleUIDefinitions: (
      state,
      action: PayloadAction<UiDefinition[]>
    ) => {
      state.uiDefinitions = [...state.uiDefinitions, ...action.payload];
    },
    updateUIDefinition: (state, action: PayloadAction<UiDefinition>) => {
      const index = state.uiDefinitions.findIndex(
        (value) => value.id === action.payload.id
      );
      if (index >= 0) {
        state.uiDefinitions.splice(index, 1, action.payload);
      } else {
        state.uiDefinitions.push(action.payload);
      }
    },

    deleteUIDefinition: (state, action: PayloadAction<UiDefinition>) => {
      const index = state.uiDefinitions.findIndex(
        (value) => value.id === action.payload.id
      );
      if (index >= 0) {
        state.uiDefinitions.splice(index, 1);
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCachedUIDefinitions.fulfilled, (state, action) => {
        state.uiDefinitions = action.payload;
      })
      .addCase(fetchAllCachedUIDefinitions.pending, (state, action) => {
        state.uiDefinitions = [];
      });
  },
});

const uiDefinitionsReducer = uiDefinitionsSlice.reducer;

export const {
  addUIDefinition,
  addMultipleUIDefinitions,
  updateUIDefinition,
  deleteUIDefinition,
} = uiDefinitionsSlice.actions;
export { fetchAllCachedUIDefinitions };
export default uiDefinitionsReducer;
