import { useEffect, useState } from "react";
import { LoraNode } from "../../../model/lora-node/LoraNode";
import { UiEnhancements } from "../../MaterialOnFire/custom-hooks/useGetRoutes";
import { GenericActionBarAction } from "../../MaterialOnFire/GenericUIFields/GenericActionBar";
import {
  readTTNDownlinkkMessage,
  readTTNUplinkMessage,
  TTNMessage,
} from "../../../model/lora-node/Messages/ttnMessages";
import {
  Box,
  Chip,
  Collapse,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ListItemButton,
  Paper,
  Typography,
} from "@mui/material";

import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

import { getLoraNodeActionsFromHardWareType } from "./MaintainLoraNodeActions";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { HardwareType } from "../../../model/hardware-type/hardwareType";
import { NodeDetails } from "../../nodeDetails/NodeDetails";
import { useSnackbar } from "notistack";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  RefreshOutlined,
} from "@mui/icons-material";
import { useTranslation } from "../../MaterialOnFire/custom-hooks/useTranslation";

interface MessageOpenInterface {
  [key: string]: boolean;
}

const prettyPrintJson = (payload: string) => {
  try {
    return (
      <pre style={{ maxWidth: "100%", fontSize: 12 }}>
        {" "}
        {JSON.stringify(JSON.parse(payload), null, 2)}{" "}
      </pre>
    );
  } catch (e) {
    return <pre> {payload}</pre>;
  }
};

const TimelineItem = (props: {
  isOpen: boolean;
  timestamp: string;
  payload: string;
  openCallback: () => void;
}) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <ListItemButton
          onClick={props.openCallback}
          sx={{ height: "100%", p: 0 }}
        >
          <Grid container sx={{ height: "100%" }}>
            <Grid item xs={2} sx={{ height: "100%" }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100%"
              >
                {props.isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Typography variant={"body2"}>{props.timestamp}</Typography>
            </Grid>
          </Grid>
        </ListItemButton>
      </Grid>

      <Grid item xs={12}>
        <Collapse in={props.isOpen} timeout={"auto"} unmountOnExit={true}>
          {prettyPrintJson(props.payload)}
        </Collapse>
      </Grid>
    </Grid>
  );
};

const MessageTimeline = (props: { selectedNode?: LoraNode }) => {
  const [messages, setMessages] = useState<TTNMessage[]>([]);
  const [messageOpen, setMessageKeyOpen] = useState<MessageOpenInterface>({});
  const [refresh, setRefresh] = useState(0);
  useEffect(() => {
    if (props.selectedNode) {
      Promise.all([
        readTTNUplinkMessage(props.selectedNode?.ttnPayload?.ids?.device_id),
        readTTNDownlinkkMessage(props.selectedNode?.ttnPayload?.ids?.device_id),
      ]).then(([uplink, downlink]) => {
        let array = [...uplink, ...downlink];
        array = array.sort((a: TTNMessage, b: TTNMessage) => {
          return b.time.getTime() - a.time.getTime();
        });
        setMessages(array);
      });
    }
  }, [props.selectedNode, refresh]);

  return (
    <>
      <Grid item xs={12} alignContent={"center"} sx={{ p: 1 }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100%"
        >
          <IconButton
            onClick={() => {
              setRefresh(refresh + 1);
            }}
            color={"secondary"}
          >
            <RefreshOutlined />
          </IconButton>
        </Box>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={{ height: "85%", width: "100%", overflowX: "auto" }}
      >
        {messages.map((line, index) => {
          return (
            <Grid item container xs={12}>
              <Grid
                item
                xs={5}
                sx={{ height: "100%", p: 1 }}
                alignContent={"center"}
                justifyContent={"center"}
              >
                {index % 2 === 0 ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100%"
                    component={Paper}
                  >
                    <TimelineItem
                      isOpen={messageOpen[index]}
                      timestamp={line.time.toString()}
                      payload={line.payload}
                      openCallback={() => {
                        const map = { ...messageOpen };
                        map[index] = !map[index];
                        setMessageKeyOpen(map);
                      }}
                    />
                  </Box>
                ) : null}
              </Grid>

              <Grid
                item
                xs={2}
                alignContent={"center"}
                justifyContent={"center"}
                sx={{ minHeight: "75px" }}
              >
                <Divider orientation={"vertical"}>
                  {line.type == "uplink" ? (
                    <ArrowCircleUpIcon color={"secondary"} />
                  ) : (
                    <ArrowCircleDownIcon color={"primary"} />
                  )}
                </Divider>
              </Grid>

              <Grid
                item
                xs={5}
                sx={{ height: "100%", p: 1 }}
                alignContent={"center"}
                justifyContent={"center"}
              >
                {index % 2 !== 0 ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100%"
                    component={Paper}
                  >
                    <TimelineItem
                      isOpen={messageOpen[index]}
                      timestamp={line.time.toString()}
                      payload={line.payload}
                      openCallback={() => {
                        const map = { ...messageOpen };
                        map[index] = !map[index];
                        setMessageKeyOpen(map);
                      }}
                    />
                  </Box>
                ) : null}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export const useGetLoraNodesUIEnhancer = () => {
  const cachedHardwaretypes = useSelector(
    (
      state: RootState // @ts-ignore
    ) => state["hardwareTypes"]?.items as unknown as HardwareType[]
  );

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  //Die GenericMaintenanceList führt das selected Item( in dem Fall die Lora Node)
  //im Redux State mit
  const selected = useSelector(
    (state: RootState) => state.maintenanceList.selected as LoraNode
  );

  const loraNodeActionBarEnhancer = (
    item: LoraNode,
    actions: GenericActionBarAction[]
  ) => {
    if (item?.hardwareType) {
      const hardwareInstructions = getLoraNodeActionsFromHardWareType(
        item?.hardwareType,
        cachedHardwaretypes,
        enqueueSnackbar
      );
      return [...actions, ...hardwareInstructions];
    }
    return actions;
  };

  const loraNodeEnhancer = {
    enhanceActionBar: loraNodeActionBarEnhancer,
    injectJSX: (
      <Grid container item xs={12} sx={{ height: "100%" }}>
        <Grid
          item
          xs={12}
          sx={{
            height: "30%",
            overflowX: "auto",
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 1,
          }}
        >
          <Paper sx={{ height: "100%", overflowX: "auto" }}>
            <Grid container sx={{ height: "100%", width: "100%" }} spacing={2}>
              <Grid item xs={12} sx={{ height: "5%" }}>
                <Typography variant={"h6"} align={"center"}>
                  {t("NodeDetails.Measurements")}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ height: "95%" }}>
                {<NodeDetails id={selected?.id} />}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            height: "70%",
            width: "100%",
            overflowX: "auto",
            paddingTop: 1,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <Paper sx={{ height: "100%", overflowX: "auto", width: "100%" }}>
            <Grid container sx={{ height: "100%", width: "100%" }} spacing={2}>
              <Grid item xs={12} sx={{ height: "5%" }}>
                <Typography variant={"h6"} align={"center"}>
                  {t("NodeDetails.Messages")}
                </Typography>
              </Grid>

              <MessageTimeline selectedNode={selected} />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    ),
  } as UiEnhancements;

  return { key: "loraNodes", enhancer: loraNodeEnhancer };
};
