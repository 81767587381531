import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { useEffect } from "react";
import {
  GenericValueHelpResult,
  registerValueHelp,
} from "../GenericUIFields/GenericSelect/GenericValueHelpState";

export const useRegisterReferenceValueHelp = () => {
  const uiDefinitions = useSelector(
    (state: RootState) => state.uiDefinitions.uiDefinitions
  );
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const valueHelp: GenericValueHelpResult[] = uiDefinitions.map((value) => {
      return {
        id: value.id,
        displayValue: value.name,
        object: value,
      };
    });
    dispatch(
      registerValueHelp({ name: "reference", valueHelpResult: valueHelp })
    );
  }, [uiDefinitions]);
};
