import * as React from "react";
import StyledAppBar from "./StyledAppBar";

import { StyledDrawer } from "./drawer/Drawer";


export default function PrimarySearchAppBar() {
  return (
   <>
     <StyledAppBar/>
     <StyledDrawer/>
   </>
  );
}
