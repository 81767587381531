import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setSearchEnabled } from "../MaterialOnFire/app-bar/appBarState";
import { readDiscoveredTTNDevices } from "../../model/lora-node/loraNodeFirebaseServices";

export const useGetNewTTNCount = () => {
  const dispatch = useDispatch();
  const [ttnDevicesCount, setTTNDevicesCount] = useState<number>(0);
  useEffect(() => {
    dispatch(setSearchEnabled(false));
    readDiscoveredTTNDevices()
      .then((result) => setTTNDevicesCount(result.length))
      .catch((e) => console.log(e));
  }, []);

  return ttnDevicesCount;
};
