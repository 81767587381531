import { UiDescriptor } from "./MaintenanceView";
import { ChangeEvent, SyntheticEvent } from "react";
import { GenericFieldInterface } from "./GenericFieldInterface";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
} from "@mui/material";
import { useTranslation } from "../custom-hooks/useTranslation";

export interface GenericCheckboxInterface
  extends Omit<GenericFieldInterface, "onChange"> {
  onChange: (event: SyntheticEvent, checked: boolean) => void;
}

export const GenericCheckbox = (props: GenericCheckboxInterface) => {
  const { t } = useTranslation();
  return (
    <Grid item xs={props.uiDescriptor.size}>
      <FormControl component="fieldset" required={props.required}>
        <FormGroup aria-label="position" row>
          <FormControlLabel
            checked={props.value || false}
            onChange={props.onChange}
            control={<Checkbox />}
            label={t(`${props.translationPrefix}.${props.fieldKey}`)}
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>
    </Grid>
  );
};
