import { generateCRUDFirebaseServices } from "./BaseFireBaseServices";
import { UiDefinition } from "./uiDefinition";
import { deleteUIDefinition, updateUIDefinition } from "./uiDefinitionState";
import { UiDescriptor } from "../../GenericUIFields/MaintenanceView";

const uiFirebaseServices = generateCRUDFirebaseServices<UiDefinition>(
  "/UIDefiniton",
  "UIDefiniton",
  (item) => updateUIDefinition(item),
  (item) => deleteUIDefinition(item)
);

const minimalUiDescriptor: UiDescriptor[] = [
  {
    fieldName: "id",
    fieldType: "textField",
    visible: true,
    required: true,
    size: 6,
  },
  {
    fieldName: "name",
    fieldType: "textField",
    visible: true,
    required: true,
    size: 6,
  },
];

const getNewUiDefinition = () => {
  const uiDefinition = {
    ...uiFirebaseServices.getNewEmptyItem(),
    uiDescriptorDefinition: minimalUiDescriptor,
    name: "New UI",
    model: "New Model",
  } as UiDefinition;
  return uiDefinition;
};

export { uiFirebaseServices, getNewUiDefinition };
