import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { PaletteOptions } from "@mui/material";
import { colors } from "../theme/ColorPalette";
import { getTheme } from "../theme/MainTheme";
import { useEffect } from "react";

export const useGetTheme = () => {
  const mode = useSelector((state: RootState) => state.appBar.themeMode);

  const themes = useSelector((state: any) => {
    return state["Theme"]?.items as any[];
  });

  if (themes) {
    const themeId = localStorage.getItem("preferredThemeId");
    const index = themes.findIndex((value) => themeId === value.id);
    if (index > -1) {
      let theme = themes[index];
      let themeColors = {
        ...theme.color,
        action:
          theme.type === "light"
            ? {
                active: theme.color.secondary.light,
                hover: theme.color.secondary.dark,
                hoverOpacity: 0.04,
                selected: theme.color.secondary.main,
                selectedOpacity: 0.008,
                focus: theme.color.secondary.light,
              }
            : {
                active: theme.color.secondary.main,
                hover: theme.color.secondary.dark,
                hoverOpacity: 0.04,
                selected: theme.color.secondary.main,
                selectedOpacity: 0.008,
                focus: theme.color.secondary.light,
              },
      };

      const paletteLight: PaletteOptions = {
        //light mode is default
        mode: "light",
        primary: theme.color.primary,
        secondary: theme.color.secondary,
        paper: theme.color.paper,
        error: theme.color.error,
        warning: theme.color.warning,
        info: theme.color.info,
        success: theme.color.success,
        action: themeColors.action,
        text: theme.color.text,
        background: theme.color.background,
      };

      return getTheme("light", themeColors, paletteLight);
    }
  }

  return getTheme("light");
};
