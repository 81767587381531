import {
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useGetRoutes } from "../../custom-hooks/useGetRoutes";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { setDrawerOpen } from "../appBarState";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { Engineering } from "@mui/icons-material";
import { useGetIconList } from "../../custom-hooks/useGetIconList";
import { useTranslation } from "../../custom-hooks/useTranslation";
export const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export const StyledDrawer = () => {
  const { getRoutes } = useGetRoutes();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const openDrawer = useSelector((state: RootState) => state.appBar.drawerOpen);
  const uiDefinitions = useSelector(
    (state: RootState) => state.uiDefinitions.uiDefinitions
  );
  const dispatch: AppDispatch = useDispatch();

  return (
    <Drawer
      variant="permanent"
      open={openDrawer}
      PaperProps={{ sx: { backgroundColor: "paper.drawer" } }}
      onClose={() => {
        dispatch(setDrawerOpen(false));
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <IconButton
          onClick={() => {
            dispatch(setDrawerOpen(false));
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <List>
        {getRoutes().map((route) => {
          return (
            <>
              <ListItemButton
                onClick={() => {
                  navigate(route?.route || "");
                  dispatch(setDrawerOpen(false));
                }}
              >
                {/*Hier benötigen wir noch die Iconen*/}
                <Tooltip title={t("Drawer." + route.name)} placement={"right"}>
                  <ListItemIcon>{route.icon}</ListItemIcon>
                </Tooltip>
                <Typography>{t("Drawer." + route.name)}</Typography>
              </ListItemButton>
              <Divider />
            </>
          );
        })}
      </List>
    </Drawer>
  );
};
