import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoraNode } from "../../../../model/lora-node/LoraNode";

export interface GenericValueHelpResult {
  id: string;
  displayValue: string;
  object: any;
}

export interface GenericValueHelp {
  [name: string]: GenericValueHelpResult[];
}

const initialState: GenericValueHelp = {};

const genericValueHelpSlice = createSlice({
  name: "GenericValueHelp",
  initialState,
  reducers: {
    registerValueHelp: (
      state,
      action: PayloadAction<{
        name: string;
        valueHelpResult: GenericValueHelpResult[];
      }>
    ) => {
      state[action.payload.name] = action.payload.valueHelpResult;
    },
  },
});

export const { registerValueHelp } = genericValueHelpSlice.actions;
const genericValueHelpReducer = genericValueHelpSlice.reducer;
export default genericValueHelpReducer;
