import React, { useEffect, useMemo, useState } from "react";
import { firebaseDB } from "../MaterialOnFire/firebase-config";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import {
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "../MaterialOnFire/custom-hooks/useTranslation";
import { getLoraNodeActionsFromHardWareType } from "../custom-hooks/uiEnhancers/MaintainLoraNodeActions";
import { HardwareType } from "../../model/hardware-type/hardwareType";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useGetTheme } from "../MaterialOnFire/custom-hooks/useGetTheme";
import { LoraNode } from "../../model/lora-node/LoraNode";
import { useSnackbar } from "notistack";

export interface KPIEntry {
  value: string;
  unit: string;
  timestamp: string;
  formatted: string;
  name: string;
}

export interface KPIDocument {
  [key: string]: KPIEntry;
}

export const NodeDetails = (props: {
  id: string;
  toolbar?: {
    showToolbar: boolean;
    hardwareType?: HardwareType;
    node: LoraNode;
    enqueueSnackbar: (text: string, options: any) => void;
  };
}) => {
  const [kpis, setKPIS] = useState<KPIDocument | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const theme = useGetTheme();

  const cachedHardwaretypes = useSelector(
    (
      state: RootState // @ts-ignore
    ) => state["hardwareTypes"]?.items as unknown as HardwareType[]
  );

  useEffect(() => {
    let docRef = doc(firebaseDB, `/KPIEntries/${props.id}`);
    let unsub = onSnapshot(docRef, (doc) => {
      setKPIS(doc.data() as unknown as KPIDocument);
      setLoading(false);
    });

    return () => {
      unsub();
    };
  }, [props.id]);

  const toolbarActions = useMemo(
    () =>
      props.toolbar?.hardwareType
        ? getLoraNodeActionsFromHardWareType(
            props.toolbar?.hardwareType,
            cachedHardwaretypes,
            props.toolbar.enqueueSnackbar
          )
        : [],
    [props.toolbar?.hardwareType, cachedHardwaretypes]
  );

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Stack sx={{ width: "100%", height: "100%" }}>
        <Grid container>
          {props.toolbar?.showToolbar
            ? toolbarActions.map((action) => {
                return (
                  <Grid item xs={1}>
                    <Tooltip title={action.name}>
                      <IconButton
                        disabled={action.disabled}
                        color={"secondary"}
                        onClick={() => action.action(props.toolbar?.node)}
                      >
                        {" "}
                        {action.icon}{" "}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                );
              })
            : null}
        </Grid>
        <TableContainer>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell align={"left"}>KPI</TableCell>
                <TableCell align={"right"}>Messwert</TableCell>
                <TableCell align={"left"}>Letzte Messung</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ overflow: "auto" }}>
              {kpis
                ? Object.entries(kpis).map((entry, index) => {
                    const [key, kpi] = [...entry];

                    if (key === "lastModified") {
                      return null;
                    }

                    return (
                      <TableRow>
                        <TableCell align={"left"}>{kpi.name}</TableCell>
                        <TableCell align={"right"}>{kpi.formatted}</TableCell>
                        <TableCell align={"left"}>{kpi.timestamp}</TableCell>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </ThemeProvider>
  );
};
