import { LatLng } from "../components/map/mapState";

const earthRadius = 6378; //Erdradius in km
const pi = Math.PI;

const addMetersToLatitude = (latitude: number, meters: number) => {
  return latitude + (meters / 1000 / earthRadius) * (180 / pi);
};

const addMetersToLongitude = (
  longitude: number,
  latitude: number,
  meters: number
) => {
  return (
    longitude +
    ((meters / 1000 / earthRadius) * (180 / pi)) /
      Math.cos((latitude * pi) / 180)
  );
};

const addMetersToGeoPoint = (
  latitude: number,
  longitude: number,
  metersLat: number,
  metersLong: number
) => {
  let newLat = addMetersToLatitude(latitude, metersLat);
  let newLong = addMetersToLongitude(longitude, latitude, metersLong);

  return { lat: newLat, lng: newLong } as LatLng;
};

export { addMetersToGeoPoint, earthRadius };
