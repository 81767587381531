import * as MUIicons from "@mui/icons-material";
import { Typography } from "@mui/material";
import { QuestionMark } from "@mui/icons-material";

export const useGetIconList = () => {
  return {
    icons: MUIicons,
    keys: Object.keys(MUIicons).filter((value) => {
      return (
        !value.match(/Outlined/g) &&
        !value.match(/Rounded/g) &&
        !value.match(/Sharp/g) &&
        !value.match(/TwoTone/g)
      );
    }),

    getIcon: (iconName: string, sx?: any) => {
      //@ts-ignore
      const Icon = MUIicons[iconName];

      return Icon ? <Icon sx={sx} /> : <QuestionMark sx={sx} />;
    },
  };
};
