import { useDispatch, useSelector } from "react-redux";
import { addReducer, AppDispatch, RootState } from "../../store";
import { useEffect, useState } from "react";
import { GenericMaintenanceList } from "./GenericUIViews/GenericMaintenanceList/GenericMaintenanceList";
import {
  addGroup,
  deleteGroup,
  Group,
  updateGroup,
} from "../../model/groups/groupState";
import { Grid, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useGetSaveAndDeleteActions } from "./custom-hooks/useGetSaveAndDeleteActions";
import { useGetUIDefinition } from "./custom-hooks/useGetUIDefinition";
import { UiEnhancements } from "./custom-hooks/useGetRoutes";
import { LoraNode } from "../../model/lora-node/LoraNode";
import { useSnackbar } from "notistack";
import {
  setInitUi,
  setIsDirty,
} from "./GenericUIViews/GenericMaintenanceList/GenericMaintenanceListState";
import { useTranslation } from "./custom-hooks/useTranslation";

export const GenericUI = (props: {
  model: string;
  uiEnhancer?: UiEnhancements;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const navigation = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const getUIDefinition = useGetUIDefinition(props.model);

  const reducer = addReducer(getUIDefinition());

  const selected = useSelector(
    (state: RootState) => state.maintenanceList.selected
  );

  //@ts-ignore
  const items = useSelector((state) => state[getUIDefinition()?.model]?.items);

  useEffect(() => {
    console.count("GenericUi Model Changes");
    //@ts-ignore
    dispatch(reducer.fetchAll());
    dispatch(setInitUi());
  }, [props.model]);
  let actions = useGetSaveAndDeleteActions<any>({
    uiDefinition: getUIDefinition(),
    saveAction: (newState) => {
      if (reducer) {
        dispatch(reducer.actions.update(newState));
        reducer.crudService
          .upsertItem(newState)
          .then(() => {
            enqueueSnackbar(t("enqueueSnackbar"), { variant: "success" });
            dispatch(setIsDirty(false));
          })
          .catch(() => {
            enqueueSnackbar(t("saveError"), { variant: "error" });
          });
      }
    },
    deleteAction: (newState) => {
      if (reducer) {
        dispatch(reducer.actions.delete(newState));
        reducer.crudService
          .deleteItem(newState)
          .then(() => {
            enqueueSnackbar(t("deleteSuccess"), { variant: "success" });
            dispatch(setIsDirty(false));
          })
          .catch(() => {
            enqueueSnackbar(t("deleteError"), { variant: "error" });
          });
      }
    },
  });

  if (props.uiEnhancer) {
    actions = props.uiEnhancer.enhanceActionBar(selected, actions);
  }

  return (
    <Grid item container sx={{ height: "100%" }} spacing={2}>
      <Grid
        item
        container
        xs={props.uiEnhancer?.injectJSX ? 8 : 12}
        sx={{ height: "100%" }}
      >
        <GenericMaintenanceList
          list={items}
          routingOptions={{
            idFieldName: "id",
            baseRoute: getUIDefinition().model,
          }}
          listItemDisplayFunction={(item: Group) => {
            return <Typography>{item.name}</Typography>;
          }}
          addNewAction={() => {
            if (reducer) {
              let newGroup = reducer.crudService.getNewEmptyItem();
              dispatch(reducer.actions.add(newGroup));
              navigation("../" + getUIDefinition().model + "/" + newGroup.id);
            }
          }}
          translationPrefix={props.model}
          uiDescriptorTable={getUIDefinition().uiDescriptorDefinition || []}
          maintenanceViewActions={actions}
        />
      </Grid>
      { (props.uiEnhancer?.injectJSX)?
        (
        <Grid
          item
          container
          xs={props.uiEnhancer?.injectJSX ? 4 : 0}
          sx={{ height: "100%"}}
        >
          {props.uiEnhancer?.injectJSX ? props.uiEnhancer?.injectJSX : ""}
        </Grid>
        ) : null
      }
    </Grid>
  );
};
