import {
  CollectionReference,
  DocumentData,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { AppDispatch } from "../../../../store";
import {
  GenericFirebaseFields,
  sanitizeFirebaseField,
} from "./BaseFireBaseServices";

export const registerCacheListener = <T extends GenericFirebaseFields>(
  collectionRef: CollectionReference<DocumentData>,
  localStorageId: string,
  entity: string,
  addFunction: (state: T) => void,
  updateFunction: (state: T) => void,
  deleteFunction: (state: T) => void
) => {
  let highestLastModifiedDate = window.localStorage.getItem(localStorageId);
  if (highestLastModifiedDate === null) {
    let date = "0";
    window.localStorage.setItem(localStorageId, date);
    highestLastModifiedDate = date;
  }

  let queryDate = parseInt(highestLastModifiedDate);
  if (!queryDate && queryDate != 0) queryDate = 0;
  const q = query(collectionRef, where("lastModified", ">", queryDate));
  //console.info(entity + "Listener Enabled for", queryDate);
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    querySnapshot.docChanges().forEach((change) => {
      console.info("New " + entity + " found", change.doc.data()?.lastModified);
      const date = change.doc.data()?.lastModified + "";
      let newDoc = sanitizeFirebaseField(change.doc.data()) as T;
      switch (change.type) {
        case "added":
          newDoc.deleted ? deleteFunction(newDoc) : addFunction(newDoc);
          break;
        case "removed":
          deleteFunction(change.doc.data() as T);
          break;
        case "modified":
          newDoc.deleted
            ? deleteFunction(newDoc)
            : updateFunction(change.doc.data() as T);
          break;
      }

      if (change.doc.data()?.lastModified > queryDate) {
        window.localStorage.setItem(localStorageId, date);

        console.info("New High Date", date);
      }
    });
  });

  return unsubscribe;
};
