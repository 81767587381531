import React, { useEffect, useState } from "react";
//import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useDispatch, useSelector } from "react-redux";
import { updateViewPort } from "./mapState";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMap,
  Popup,
  useMapEvents,
} from "react-leaflet";
import MapHandler from "./MapHandler";
import { addReducer, AppDispatch, RootState } from "../../store";
import { fetchAllCachedLoraNodes } from "../../model/lora-node/loraNodeState";
import { useGetUIDefinition } from "../MaterialOnFire/custom-hooks/useGetUIDefinition";
import { LoraNode } from "../../model/lora-node/LoraNode";

const containerStyle = {
  width: "75vw",
  height: "75vh",
};

const defaultCenter = {
  lat: 51.25644446909589,
  lng: 7.14607843104575,
};

const getInitialMapCenter = () => {
  let urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("lat") && urlParams.has("lng")) {
    let lat = Number.parseFloat(urlParams.get("lat") || "0");
    let lng = Number.parseFloat(urlParams.get("lng") || "0");
    return { lat: lat, lng: lng };
  } else {
    let lat = localStorage.getItem("mapCenterLat");
    let lng = localStorage.getItem("mapCenterLng");
    if (lat && lng) {
      return {
        lat: Number.parseFloat(lat),
        lng: Number.parseFloat(lng),
      };
    }
  }

  return defaultCenter;
};

const defaultZoom = 13;
const getInitialZoom = () => {
  let urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("zoomLevel")) {
    return Number.parseInt(urlParams.get("zoomLevel") || "0");
  } else {
    let zoomLevel = localStorage.getItem("mapZoomLevel");

    if (zoomLevel) {
      return Number.parseInt(zoomLevel);
    }
  }

  return defaultZoom;
};

function KapionMap() {
  const getLoraNodesUIDefinition = useGetUIDefinition("loraNodes");

  const nodes = useSelector(
    (state: any) => state["loraNodes"]?.items as LoraNode[]
  );
  const searchString = useSelector(
    (state: RootState) => state.appBar.searchString
  );

  const dispatch: AppDispatch = useDispatch();
  const center = getInitialMapCenter();
  const zoom = getInitialZoom();

  useEffect(() => {
    const loraNodeServices = addReducer(getLoraNodesUIDefinition());
    if (loraNodeServices?.fetchAll) {
      // dispatch(loraNodeServices?.fetchAll());
    }
  }, []);

  const [localNodes, setLocalNodes] = useState(nodes);
  useEffect(() => {
    if (nodes) {
      setLocalNodes(
        nodes.filter((value) => {
          return true;
          if (searchString === "") {
            return true;
          }
          return value?.uniqueId?.toString().includes(searchString) || !value;
        })
      );
    }
  }, [nodes, searchString]);

  return (
    <MapContainer
      center={[center.lat, center.lng]}
      zoom={zoom}
      maxZoom={18}
      scrollWheelZoom={false}
      className="markercluster-map"
      style={{ height: "100%", width: "100%" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        maxNativeZoom={19}
        maxZoom={21}
      />

      <MapHandler loraNodes={nodes} />
    </MapContainer>
  );
}

export default KapionMap;
