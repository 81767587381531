import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  getDocsFromCache,
} from "firebase/firestore";
import { firebaseDB } from "../firebase-config";
import { Translations } from "./translationsState";

import { DocumentData, QuerySnapshot } from "firebase/firestore";

export const upsertTranslationToFirebase = async (
  translations: Translations
) => {
  await setDoc(
    doc(firebaseDB, `/translation/`, translations.language),
    translations.translations
  );
};

export const fetchAllTranslationsFromCache = async () => {
  const q = query(collection(firebaseDB, "/translation"));

  let querySnapshot: QuerySnapshot<DocumentData>;

  querySnapshot = await getDocsFromCache(q);

  const results: Translations[] = [];

  querySnapshot.forEach((doc) => {
    results.push({
      language: doc.id as string,
      translations: doc.data() as any,
    });
  });

  return results;
};

export const fetchAllTranslatonsFromFirebase = async () => {
  const q = query(collection(firebaseDB, "/translation"));

  let querySnapshot: QuerySnapshot<DocumentData>;

  querySnapshot = await getDocs(q);

  const results: Translations[] = [];

  querySnapshot.forEach((doc) => {
    results.push({
      language: doc.id as string,
      translations: doc.data() as any,
    });
  });

  return results;
};
