import React, { useEffect, useState } from "react";
import logo from "./logo.svg";

import { getAuth } from "firebase/auth";
import { fireBaseApp } from "./components/MaterialOnFire/firebase-config";
import { useDispatch, useSelector } from "react-redux";
import {
  changeLoggedInStatus,
  KapionUser,
  setUser,
} from "./components/MaterialOnFire/login/userSate";
import { AppDispatch, RootState } from "./store";
import Login from "./components/MaterialOnFire/login/Login";
import { BrowserRouter, Route, Routes, useRoutes } from "react-router-dom";
import PrimarySearchAppBar from "./components/MaterialOnFire/app-bar/PrimarySearchAppBar";
import { DynamicRoutes } from "./components/MaterialOnFire/DynamicRoutes";
import { useGetUiEnhancers } from "./components/custom-hooks/useGetUIEnhancers";
import { getTheme } from "./components/MaterialOnFire/theme/MainTheme";
import {
  Box,
  LinearProgress,
  responsiveFontSizes,
  ThemeProvider,
  Toolbar,
} from "@mui/material";
import MapBasedDashboard from "./components/map/MapBasedDashboard";
import { MapRounded } from "@mui/icons-material";
import LandingPageTile from "./components/MaterialOnFire/landing-page/LandingPageTile";
import { MaterialOnFire } from "./components/MaterialOnFire/MaterialOnFire";
import { firebaseConfig } from "./firebaseConfig";

function App() {
  const uiEnhancers = useGetUiEnhancers();
  return (
    <MaterialOnFire
      uiEnhancers={uiEnhancers}
      firebaseConfig={firebaseConfig}
      customRoutes={[
        {
          route: "/map",
          name: "map",
          component: <MapBasedDashboard />,
          icon: <MapRounded />,
          landingPageTile: (
            <LandingPageTile
              name={"landingPage.map"}
              link={"/map"}
              Icon={"MapRounded"}
            />
          ),
        },
      ]}
    />
  );
}

export default App;
