import { createTheme } from "@mui/material/styles";
import { colors, paletteDark, paletteLight } from "./ColorPalette";
import { themeMode } from "../app-bar/appBarState";
import { PaletteOptions } from "@mui/material";
declare module "@mui/material/styles" {
  /*    Fucking hell if you change things in here you have to restart the Script*/
  interface Palette {
    paper: {
      main: string;
      light: string;
      dark: string;
      default: string;
      error: string;
      drawer: string;
    };
  }

  interface PaletteOptions {
    paper?: {
      main?: string;
      light?: string;
      dark?: string;
      default?: string;
      error?: string;
      drawer?: string;
    };
  }
}

const getTheme = (
  mode: string,
  externalColorPalette?: any,
  externalOptionsLight?: PaletteOptions,
  externalOptionsDark?: PaletteOptions
) => {
  let paletteOptions = mode === themeMode.light ? paletteLight : paletteDark;
  let colorPalette = mode === themeMode.light ? colors.light : colors.dark;
  if (externalColorPalette) {
    colorPalette =
      mode === themeMode.light ? externalColorPalette : externalColorPalette;
  }

  if (externalOptionsLight) {
    paletteOptions =
      mode === themeMode.light ? externalOptionsLight : paletteDark;
  }

  if (externalOptionsDark) {
    paletteOptions =
      mode === themeMode.light ? paletteLight : externalOptionsDark;
  }

  return createTheme({
    palette: paletteOptions,
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            "& .MuiInputLabel-root": {
              color: colorPalette.text.primary,
            },
            "& label.Mui-focused": {
              color: colorPalette.action.focus,
            },

            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: colorPalette.action.focus,
              },
              "&:hover fieldset": {
                borderColor: colorPalette.action.focus,
              },
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: colorPalette.text.primary,
            "&.Mui-checked": { color: colorPalette.secondary.main },
          },
        },
      },

      MuiAccordion: {
        styleOverrides: {
          root: {
            width: "100%",
            backgroundColor: colorPalette.paper.dark,
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: colorPalette.action.selected,
            },
          },
        },
      },
    },
  });
};

export { getTheme };
