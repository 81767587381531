import {
    doc,
    setDoc,
    getDoc,
    getDocFromCache,
    orderBy,
    collection,
    getDocsFromCache,
    where,
    query,
    getDocs,
} from "firebase/firestore";
import {firebaseDB} from "../../firebase-config";
import {registerCacheListener} from "./CacheListener";
import {AppDispatch} from "../../../../store";
import {PayloadAction} from "@reduxjs/toolkit";

export interface GenericFirebaseFields {
    id: string;
    deleted: boolean;
}

const sanitizeFirebaseField = (item: any) => {
    let itemCopy: any = {...item};
    for (let key of Object.keys(itemCopy)) {
        if (key.substring(0, 2) === "__") {
            itemCopy[key] = null;
        } else {
            if (typeof itemCopy[key] === "object" && itemCopy[key] != null && !Array.isArray(itemCopy[key])) {
                let miniCopy = {...itemCopy[key]};
                for (let subKey of Object.keys(itemCopy[key])) {
                    if (subKey.substring(0, 2) === "__") {
                        miniCopy[subKey] = null;
                    }
                }
                itemCopy[key] = miniCopy;
            }
        }
    }

    return itemCopy;
};

const generateCRUDFirebaseServices = <T extends GenericFirebaseFields>(
    basePath: string,
    cacheId: string,
    reduxUpsertFunction: (state: T, action?: PayloadAction<T>) => void,
    reduxDeleteFunction: (state: T, action?: PayloadAction<T>) => void
) => {
    const injectFirebaseRef = (item: T) => {
        const itemCopy: any = {...item};
        itemCopy.__linkedFirebaseReferences = [];
        for (let key of Object.keys(itemCopy)) {
          // Since firebase now stores null values, we delete key values pairs, with null values from the json
            if (itemCopy[key] == null) {
                delete itemCopy[key]
            } else {
                //@ts-ignore
                if (typeof itemCopy[key] == "object") {
                    let value = {...itemCopy[key]};
                    if (value?._needsFirebaseRef) {
                        value.__fireBaseRef = getDocRef(value.id, value?._fireBasePath);
                        itemCopy[key] = value;
                        itemCopy.__linkedFirebaseReferences.push(value.__fireBaseRef);
                    }
                }
            }
        }
        console.log(itemCopy);
        return itemCopy;
    };

    const upsertItem = async (item: T) => {
        await setDoc(
            doc(firebaseDB, basePath, item.id),
            injectFirebaseRef({...item})
        );
    };

    const deleteItem = async (item: T) => {
        await setDoc(
            doc(firebaseDB, basePath, item.id),
            injectFirebaseRef({...item, deleted: true})
        );
    };

    const readItem = async (id: string) => {
        try {
            const itemSnap = await getDocFromCache(doc(firebaseDB, basePath, id));
            return itemSnap.data() as T;
        } catch (e) {
            try {
                const itemSnap2 = await getDoc(doc(firebaseDB, basePath, id));
                return sanitizeFirebaseField(itemSnap2.data()) as T;
            } catch (e2) {
                throw e2;
            }
        }
    };

    const getDocRef = (id: string, path?: string) => {
        return doc(firebaseDB, path || basePath, id);
    };

    const registerItemSnapShotListener = (dispatch: AppDispatch) => {
        return registerCacheListener<T>(
            collection(firebaseDB, basePath),
            cacheId,
            cacheId,
            // @ts-ignore
            (state: T) => dispatch(reduxUpsertFunction(state)),
            // @ts-ignore
            (state: T) => dispatch(reduxUpsertFunction(state)),
            //@ts-ignore
            (state) => dispatch(reduxDeleteFunction(state))
        );
    };

    const readCachedItems = getReadCachedItemsFunction<T>(basePath);

    const getNewEmptyItem = () => {
        return {
            id: crypto.randomUUID(),
            deleted: false,
        } as T;
    };

    const readDeltaItemsAsOfTimestamp = getReadDeltaItemsAsOfTimeStamp(
        basePath,
        cacheId
    );

    return {
        getNewEmptyItem,
        upsertItem,
        readItem,
        getDocRef,
        readCachedItems,
        registerItemSnapShotListener,
        deleteItem,
        readDeltaItemsAsOfTimestamp,
    };
};

const getReadCachedItemsFunction = <T>(basePath: string) => {
    return async () => {
        const q = query(
            collection(firebaseDB, basePath),
            where("deleted", "!=", true)
        );
        try {
            const snapshot = await getDocsFromCache(q);
            const items: T[] = [];

            snapshot.forEach((doc) => {
                items.push(sanitizeFirebaseField(doc.data()) as T);
            });

            return items;
        } catch (e) {
            return [] as T[];
        }
    };
};

const getReadDeltaItemsAsOfTimeStamp = <T>(
    basePath: string,
    cacheId: string
) => {
    return async () => {
        let highestLastModifiedDate = window.localStorage.getItem(cacheId);
        if (highestLastModifiedDate === null) {
            let date = "0";
            window.localStorage.setItem(cacheId, date);
            highestLastModifiedDate = date;
        }

        let queryDate = parseInt(highestLastModifiedDate);
        if (!queryDate && queryDate != 0) queryDate = 0;
        const q = query(
            collection(firebaseDB, basePath),
            orderBy("lastModified", "desc"),
            where("lastModified", ">", queryDate)
        );

        const data = (await getDocs(q)).docs.map((doc) => {
            const data = doc.data() as T;
            //@ts-ignore
            if (data?.lastModified > queryDate) {
                //@ts-ignore
                queryDate = data.lastModified;
            }
            return data;
        });

        window.localStorage.setItem(cacheId, queryDate + "");

        return data;
    };
};
export {
    generateCRUDFirebaseServices,
    getReadCachedItemsFunction,
    sanitizeFirebaseField,
    getReadDeltaItemsAsOfTimeStamp,
};
