import { MaintenanceView, UiDescriptor } from "../MaintenanceView";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useTranslation } from "../../custom-hooks/useTranslation";

export const GenericTablePopup = (props: {
  data: any;
  open: boolean;
  uiDescriptorTable: UiDescriptor[];
  translationPrefix: string;
  handleOkay: (data: any) => void;
  handleClose: () => void;
}) => {
  const { t } = useTranslation();
  const [dialogData, setDialogData] = useState(props.data);

  useEffect(() => {
    setDialogData(props.data);
  }, [props.data]);

  return (
    <Dialog open={props.open}>
      <Toolbar>
        <Typography>Here goes the Toolbar</Typography>
      </Toolbar>
      <DialogContent>
        <MaintenanceView
          data={dialogData}
          translationPrefix={props.translationPrefix}
          uiDescriptorTable={props.uiDescriptorTable}
          actions={[]}
          hideFieldsWithoutUiDescriptor={false}
          asSection={true}
          updateBaseData={(data) => {
            setDialogData(data);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color={"primary"}
          variant={"contained"}
          onClick={() => {
            props.handleOkay(dialogData);
          }}
        >
          {t("GenericTablePopup.ButtonOk")}{" "}
        </Button>
        <Button onClick={props.handleClose}>
          {t("GenericTablePopup.ButtonCancel")}{" "}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
