import { GenericFieldInterface } from "../GenericFieldInterface";

import { Chip, Grid, List, ListItem, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "../../custom-hooks/useTranslation";

interface GenericTokenizerInterface
  extends Omit<GenericFieldInterface, "onChange"> {
  onChange: (e: string[]) => void;
}
export const GenericTokenizer = (props: GenericTokenizerInterface) => {
  const { t } = useTranslation();
  const [textFieldValue, setTextFieldValue] = useState("");
  const [tokens, setTokens] = useState<string[]>([]);

  //in case someone had supplied a different fiel type beforehands
  //we need to make sure that with the next call we change the value to
  //an array in order to ensure that the tokenizer works correctly
  useEffect(() => {
    if (props.value?.map) {
      setTokens(props.value);
    } else {
      setTokens([]);
    }
  }, [props.value]);

  return (
    <Grid item container xs={props.uiDescriptor.size}>
      <Grid xs={12}>
        {props.disabled ? (
          <></>
        ) : (
          <TextField
            fullWidth
            variant="standard"
            onChange={(e) => setTextFieldValue(e.currentTarget.value)}
            value={textFieldValue}
            label={t(
              props.uiDescriptor.fieldLabel || props.uiDescriptor.fieldName
            )}
            required={props.required}
            onKeyPress={(e) => {
              if (e.key.toLowerCase() === "enter") {
                props.onChange([...tokens, textFieldValue]);
                setTextFieldValue("");
              }
            }}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Stack direction={"row"} spacing={1}>
          {tokens.map((token, index) => {
            return (
              <Chip
                label={token}
                onDelete={
                  !props.disabled
                    ? () => {
                        let copy = [...tokens];
                        copy.splice(index, 1);
                        props.onChange(copy);
                      }
                    : undefined
                }
              />
            );
          })}
        </Stack>
      </Grid>
    </Grid>
  );
};
