import { useGetIconList } from "../custom-hooks/useGetIconList";
import {
  Dialog,
  DialogContent,
  Grid,
  Icon,
  IconButton,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  Pagination,
  Paper,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "../custom-hooks/useTranslation";

const VISIBLE_ITEMS = 36;
export const IconChooser = (props: {
  open: boolean;
  onSelectCallback: (IconId: string) => void;
  onClose: () => void;
}) => {
  const icons = useGetIconList();
  const { t } = useTranslation();

  const [searchString, setSearchString] = useState("");
  const [keys, setKeys] = useState(icons.keys);
  const [visibleKeys, setVisibleKeys] = useState<string[]>([]);
  //@ts-ignore
  const [timeOutId, setTimeOutId] = useState<Timeout | null>(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const regex = new RegExp(searchString);

    const newKeys = icons.keys.filter((value) => value.match(regex));
    setKeys(newKeys);

    setVisibleKeys(
      newKeys.slice(
        (page - 1) * VISIBLE_ITEMS,
        (page - 1) * VISIBLE_ITEMS + VISIBLE_ITEMS
      )
    );
  }, [searchString, page]);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogContent>
        <Grid container component={Paper}>
          <Grid item xs={12}>
            <TextField
              title={t("IconChooser.searchBar")}
              fullWidth
              onChange={(e) => {
                if (timeOutId) {
                  clearTimeout(timeOutId);
                }
                const value = e.currentTarget.value;
                const time = setTimeout(() => {
                  setSearchString(value);
                  setPage(1);
                }, 1000);
                setTimeOutId(time);
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Pagination
              count={Math.round(keys.length / VISIBLE_ITEMS)}
              page={page}
              size={"large"}
              onChange={(e, page) => {
                setPage(page);
              }}
            ></Pagination>
          </Grid>

          {visibleKeys
            .map((key) => {
              //@ts-ignore

              return (
                <Grid item xs={2}>
                  <Tooltip title={key}>
                    <IconButton onClick={() => props.onSelectCallback(key)}>
                      {icons.getIcon(key)}
                    </IconButton>
                  </Tooltip>
                </Grid>
              );
            })
            .splice(0, VISIBLE_ITEMS)}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
