import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DynamicUiFrameWorkState {
  isInitialized: boolean;
}

const initialState: DynamicUiFrameWorkState = {
  isInitialized: false,
};
const DynamicUiSlice = createSlice({
  name: "DynamicUi",
  initialState,
  reducers: {
    setDynamicUiIsInitialized: (state, action: PayloadAction<boolean>) => {
      state.isInitialized = action.payload;
    },
  },
});

export const { setDynamicUiIsInitialized } = DynamicUiSlice.actions;
export const dynamicUiReducer = DynamicUiSlice.reducer;
