import { LatLng, ViewPort } from "../../components/map/mapState";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { geohashForLocation } from "geofire-common";
import {
  loraNodeCRUDServices,
  readDiscoveredTTNDevices,
} from "./loraNodeFirebaseServices";
import { LoraNode } from "./LoraNode";
import { ttnDevice } from "../../backend/theThingsNetworkAPI";

export interface Lamp {
  id?: number;
  geoLocation?: LatLng;
  geoHash?: string;
  name?: string;
  LoRaId?: string;
}

export interface LoraNodeState {
  loraNodes: LoraNode[];
  ttnDevices: ttnDevice[];
}

const fetchAllCachedLoraNodes = createAsyncThunk(
  "nodes/fetchAllCachedLoraNodes",
  async () => {
    const response = await loraNodeCRUDServices.readCachedItems();
    return response;
  }
);

const fetchAllDiscoveredTTnDevices = createAsyncThunk(
  "nodes/fetchAllDiscoveredTTnDevices",
  async () => {
    const response = await readDiscoveredTTNDevices();
    return response;
  }
);

const initialState: LoraNodeState = {
  loraNodes: [],
  ttnDevices: [],
};

export const nodeSlice = createSlice({
  name: "nodes",
  initialState,
  reducers: {
    addLoraNode: (state, action: PayloadAction<LoraNode>) => {
      let index = state.loraNodes.findIndex(
        (value) => value.uniqueId === action.payload.uniqueId
      );
      if (index > -1) {
        state.loraNodes.splice(index, 1, action.payload);
      } else {
        state.loraNodes.push(action.payload);
      }
    },

    deleteLoraNode: (state, action: PayloadAction<LoraNode>) => {
      let index = state.loraNodes.findIndex(
        (value) => value.uniqueId === action.payload.uniqueId
      );
      if (index > -1) {
        state.loraNodes.splice(index, 1);
      }
    },

    addMultipleLoraNodes: (state, action: PayloadAction<LoraNode[]>) => {
      state.loraNodes = [...state.loraNodes, ...action.payload];
    },
    updateLoraNode: (state, action: PayloadAction<LoraNode>) => {
      state.loraNodes = [...state.loraNodes].map((node) => {
        return node.uniqueId === action.payload.uniqueId
          ? action.payload
          : node;
      });
    },
    addTTnDevice: (state, action: PayloadAction<ttnDevice>) => {
      state.ttnDevices.push(action.payload);
    },
    addMultipleTTnDevices: (state, action: PayloadAction<ttnDevice[]>) => {
      state.ttnDevices = [...state.ttnDevices, ...action.payload];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCachedLoraNodes.fulfilled, (state, action) => {
        state.loraNodes = action.payload;
      })
      .addCase(fetchAllCachedLoraNodes.pending, (state, action) => {
        state.loraNodes = [];
      })
      .addCase(fetchAllDiscoveredTTnDevices.fulfilled, (state, action) => {
        state.ttnDevices = action.payload;
      })
      .addCase(fetchAllDiscoveredTTnDevices.pending, (state, action) => {
        state.ttnDevices = [];
      });
  },
});

const nodeReducer = nodeSlice.reducer;

export const {
  addLoraNode,
  addMultipleLoraNodes,
  addTTnDevice,
  addMultipleTTnDevices,
  updateLoraNode,
  deleteLoraNode,
} = nodeSlice.actions;
export { fetchAllCachedLoraNodes, fetchAllDiscoveredTTnDevices };
export default nodeReducer;
