import {
  ChangeEvent,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import {
  Grid,
  IconButton,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";

import { GenericActionBarAction, GenericActionBar } from "./GenericActionBar";
import { GenericTextEditField } from "./GenericTextEditField";
import { GenericTable } from "./GenericTable/GenericTable";
import { GenericCheckbox } from "./GenericCheckbox";
import { GenericSelect } from "./GenericSelect/GenericSelect";
import { GenericTokenizer } from "./GenericTokenizer/GenericTokenizer";
import { UiDefinition } from "../UI-Builder/uiBuilderServices/uiDefinition";
import { AppDispatch } from "../../../store";
import { setIsDirty } from "../GenericUIViews/GenericMaintenanceList/GenericMaintenanceListState";
import { GenericIconField } from "./GenericIconField";
import { useTranslation } from "../custom-hooks/useTranslation";

export enum FieldTypes {
  "readOnlyField" = "readOnlyField",
  "textField" = "textField",
  "dateField" = "dateField",
  "table" = "table",
  "object" = "object",
  "checkbox" = "checkbox",
  "numberField" = "numberField",
  "timeField" = "timeField",
  "selectField" = "selectField",
  "tokenizer" = "tokenizer",
  "icon" = "icon",
  "color" = "color",
  "password" = "password",
}

export interface UiDescriptor {
  fieldName: string;
  fieldLabel?: string;
  fieldType:
    | "readOnlyField"
    | "textField"
    | "dateField"
    | "table"
    | "object"
    | "checkbox"
    | "numberField"
    | "timeField"
    | "selectField"
    | "tokenizer"
    | "icon"
    | "color"
    | "password";
  visible: boolean;
  reference?: UiDefinition;
  section?: boolean;
  required: boolean;
  validator?: string;
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  childUIDescriptor?: UiDescriptor[];
}

export function MaintenanceView(props: {
  data: any;
  uiDescriptorTable: UiDescriptor[];
  translationPrefix: string;
  titleSettings?: {
    title: string;
    shouldBeRendered: boolean;
  };
  actions: GenericActionBarAction[];
  updateBaseData?: (localData: any) => void;
  hideFieldsWithoutUiDescriptor: boolean;
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  asSection?: boolean;
}) {
  const { t } = useTranslation();
  const [localData, setLocalData] = useState(props.data);
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    setLocalData(props.data);
  }, [props.data]);

  if (!localData) return <div />;

  let uiComponents = props.uiDescriptorTable.map((uiDescriptorLine) => {
    let uiDescriptor = { ...uiDescriptorLine };
    let value = localData[uiDescriptor.fieldName];

    if (
      value instanceof Object &&
      value !== null &&
      uiDescriptor.fieldType != "selectField" &&
      uiDescriptor.fieldType !== "tokenizer"
    ) {
      uiDescriptor.fieldType = "object";
    }

    if (Array.isArray(value) && uiDescriptor.fieldType !== "tokenizer") {
      uiDescriptor.fieldType = "table";
    }

    if (!uiDescriptor.visible && uiDescriptor.fieldType != "object") {
      return <></>;
    }

    const onChange = (value: any) => {
      let dataCopy = { ...localData };
      dataCopy[uiDescriptor.fieldName] = value;
      if (props.updateBaseData) {
        props.updateBaseData(dataCopy);
      } else {
        setLocalData(dataCopy);
      }
      dispatch(setIsDirty(true));
    };

    const onChangeTextEdit = (
      e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      onChange(e.currentTarget.value);
    };

    const onChangeCheckbox = (e: SyntheticEvent, checked: boolean) => {
      onChange(checked);
    };

    const onChangeSelect = (e: any) => {
      onChange(e);
    };

    const onChangeIconField = (e: string) => {
      onChange(e);
    };

    const onChangeTokenizer = (e: string[]) => {
      onChange(e);
    };

    switch (uiDescriptor.fieldType) {
      case "textField":
        return (
          <GenericTextEditField
            translationPrefix={props.translationPrefix}
            fieldKey={uiDescriptor.fieldName}
            value={localData[uiDescriptor.fieldName]}
            required={uiDescriptor.required}
            uiDescriptor={uiDescriptor}
            onChange={onChangeTextEdit}
            type={"text"}
          />
        );
      case "password":
        return (
          <GenericTextEditField
            translationPrefix={props.translationPrefix}
            fieldKey={uiDescriptor.fieldName}
            value={localData[uiDescriptor.fieldName]}
            required={uiDescriptor.required}
            uiDescriptor={uiDescriptor}
            onChange={onChangeTextEdit}
            type={"password"}
          />
        );
      case "color":
        return (
          <GenericTextEditField
            translationPrefix={props.translationPrefix}
            fieldKey={uiDescriptor.fieldName}
            value={localData[uiDescriptor.fieldName]}
            required={uiDescriptor.required}
            uiDescriptor={uiDescriptor}
            onChange={onChangeTextEdit}
            type={"color"}
          />
        );
      case "icon":
        return (
          <GenericIconField
            translationPrefix={props.translationPrefix}
            onChange={onChangeIconField}
            fieldKey={uiDescriptor.fieldName}
            value={localData[uiDescriptor.fieldName]}
            required={uiDescriptor.required}
            uiDescriptor={uiDescriptor}
          />
        );
      case "dateField":
        return (
          <GenericTextEditField
            translationPrefix={props.translationPrefix}
            fieldKey={uiDescriptor.fieldName}
            value={localData[uiDescriptor.fieldName]}
            required={uiDescriptor.required}
            uiDescriptor={uiDescriptor}
            onChange={onChangeTextEdit}
            type={"date"}
          />
        );

      case "numberField":
        return (
          <GenericTextEditField
            translationPrefix={props.translationPrefix}
            fieldKey={uiDescriptor.fieldName}
            value={localData[uiDescriptor.fieldName]}
            uiDescriptor={uiDescriptor}
            required={uiDescriptor.required}
            onChange={onChangeTextEdit}
            type={"number"}
          />
        );

      case "timeField":
        return (
          <GenericTextEditField
            translationPrefix={props.translationPrefix}
            fieldKey={uiDescriptor.fieldName}
            value={localData[uiDescriptor.fieldName]}
            uiDescriptor={uiDescriptor}
            required={uiDescriptor.required}
            onChange={onChangeTextEdit}
            type={"time"}
          />
        );
      case "selectField":
        return (
          <GenericSelect
            translationPrefix={props.translationPrefix}
            fieldKey={uiDescriptor.fieldName}
            value={localData[uiDescriptor.fieldName]}
            uiDescriptor={uiDescriptor}
            required={uiDescriptor.required}
            onChange={onChangeSelect}
          />
        );

      case "readOnlyField":
        return (
          <GenericTextEditField
            translationPrefix={props.translationPrefix}
            fieldKey={uiDescriptor.fieldName}
            value={localData[uiDescriptor.fieldName]}
            disabled={true}
            required={uiDescriptor.required}
            uiDescriptor={uiDescriptor}
            type={"text"}
          />
        );
      case "checkbox":
        return (
          <GenericCheckbox
            translationPrefix={props.translationPrefix}
            onChange={onChangeCheckbox}
            fieldKey={uiDescriptor.fieldName}
            value={localData[uiDescriptor.fieldName]}
            required={uiDescriptor.required}
            uiDescriptor={uiDescriptor}
          />
        );
      case "table":
        return (
          <GenericTable
            translationPrefix={`${props.translationPrefix}.${uiDescriptor.fieldName}`}
            table={localData[uiDescriptor.fieldName] || []}
            uiDescriptorTable={uiDescriptor.childUIDescriptor || []}
            updateTable={(table) => {
              let copy = { ...localData };
              copy[uiDescriptor.fieldName] = table;
              if (props.updateBaseData) {
                props.updateBaseData(copy);
              } else {
                setLocalData(copy);
              }
            }}
          />
        );
      case "object":
        return (
          <MaintenanceView
            translationPrefix={`${props.translationPrefix}.${uiDescriptor.fieldName}`}
            data={localData[uiDescriptor.fieldName] || {}}
            uiDescriptorTable={uiDescriptor.childUIDescriptor || []}
            actions={[]}
            titleSettings={{
              shouldBeRendered: uiDescriptor.visible,
              title: uiDescriptor.fieldLabel || "",
            }}
            asSection={uiDescriptor?.section}
            hideFieldsWithoutUiDescriptor={props.hideFieldsWithoutUiDescriptor}
            updateBaseData={(data) => {
              let dataCopy = { ...localData };
              dataCopy[uiDescriptor.fieldName] = data;

              if (props.updateBaseData) {
                props.updateBaseData(dataCopy);
              } else {
                setLocalData(dataCopy);
              }
            }}
            size={uiDescriptor.size}
          />
        );

      case "tokenizer":
        return (
          <GenericTokenizer
            translationPrefix={props.translationPrefix}
            fieldKey={uiDescriptor.fieldName}
            value={localData[uiDescriptor.fieldName] || []}
            uiDescriptor={uiDescriptor}
            required={uiDescriptor.required}
            onChange={onChangeTokenizer}
          />
        );

      default:
        return (
          <GenericTextEditField
            translationPrefix={props.translationPrefix}
            fieldKey={uiDescriptor.fieldName}
            value={localData[uiDescriptor.fieldName]}
            uiDescriptor={uiDescriptor}
            required={uiDescriptor.required}
            onChange={onChangeTextEdit}
            type={"text"}
          />
        );
    }
  });

  return props.asSection ? (
    <Grid
      container
      item
      spacing={2}
      xs={props?.size || 12}
      alignContent={"center"}
      alignItems={"center"}
    >
      {props?.titleSettings?.shouldBeRendered ? (
        <Grid item xs={12}>
          <Typography>{t(props.titleSettings.title)} </Typography>
        </Grid>
      ) : (
        <></>
      )}
      <GenericActionBar state={localData} actions={props.actions} />
      {uiComponents}
    </Grid>
  ) : (
    <>{uiComponents}</>
  );
}
