import { GenericFieldInterface } from "./GenericFieldInterface";
import { useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
  Tooltip,
} from "@mui/material";
import { useGetIconList } from "../custom-hooks/useGetIconList";
import { Collections, OpenInNew } from "@mui/icons-material";
import { IconChooser } from "../UI-Builder/IconChooser";
import { useTranslation } from "../custom-hooks/useTranslation";

export interface GenericIconFieldInterface
  extends Omit<GenericFieldInterface, "onChange"> {
  onChange: (e: string) => void;
}
export const GenericIconField = (props: GenericIconFieldInterface) => {
  const [open, setOpen] = useState(false);
  const icons = useGetIconList();
  const { t } = useTranslation();
  return (
    <Grid item xs={props.uiDescriptor.size}>
      <FormControl component="fieldset" fullWidth required={props.required}>
        <InputLabel id={props.uiDescriptor.fieldName}>
          {t(`${props.translationPrefix}.${props.fieldKey}`)}
        </InputLabel>

        <Select
          value={props.value}
          open={false}
          renderValue={() => {
            return props.value ? icons.getIcon(props.value) : <></>;
          }}
          onOpen={() => setOpen(true)}
        ></Select>
      </FormControl>

      {open && (
        <IconChooser
          open={open}
          onSelectCallback={(e) => {
            props.onChange(e);
            setOpen(false);
          }}
          onClose={() => setOpen(false)}
        />
      )}
    </Grid>
  );
};
