import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { User } from "@firebase/auth/dist/auth-public";
import { ParsedToken } from "firebase/auth";

export interface KapionUser {
  displayName: string;
  email: string;
  verified: boolean;
  uid: string;
  claims: ParsedToken | null;
}

export interface UserSate {
  loggedIn: boolean;
  user: KapionUser | null;
}

const initialState: UserSate = {
  loggedIn: false,
  user: null,
};

export const getUserRole = (user: KapionUser | null) => {
  if (user?.claims?.KapionAdmin) return "KapionAdmin";

  if (user?.claims?.Admin) return "Admin";

  if (user?.claims?.User) return "User";

  return "No Role";
};

export const userHasPermission = (
  userRole: string,
  uiRole: string | undefined
) => {
  switch (userRole) {
    case "User":
      if (uiRole !== "User") return false;
      else return true;
    case "Admin":
      if (uiRole !== "User" && uiRole !== "Admin") return false;
      else return true;

    case "KapionAdmin":
      return true;

    default:
      return false;
  }
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<KapionUser | null>) => {
      state.user = action.payload;
    },
    changeLoggedInStatus: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
  },
});

export const { setUser, changeLoggedInStatus } = userSlice.actions;
export const userReducer = userSlice.reducer;
