import { LoraNode } from "../../../model/lora-node/LoraNode";
import { GenericActionBarAction } from "../../MaterialOnFire/GenericUIFields/GenericActionBar";
import { getLoraNodeActionsFromHardWareType } from "./MaintainLoraNodeActions";
import MessageIcon from "@mui/icons-material/Message";
import { getFunctions, httpsCallable } from "firebase/functions";
import { fireBaseApp } from "../../MaterialOnFire/firebase-config";
import { UiEnhancements } from "../../MaterialOnFire/custom-hooks/useGetRoutes";
import { AdminPanelSettings, Delete, Info, Save } from "@mui/icons-material";
import { resolveAny } from "dns";
import { useSnackbar } from "notistack";

const deleteUser = (
  state: any,
  deleteAction: GenericActionBarAction,
  enqueueSnackbar: any
) => {
  const functions = getFunctions(fireBaseApp, "europe-west1");
  const deleteUserCallable = httpsCallable(functions, "deleteUserCallable");

  deleteUserCallable({ uid: state?.uid })
    .then(() => deleteAction.action(state))
    .catch((e) => enqueueSnackbar(e.message, { variant: "error" }));
};

const saveUser = (
  state: any,
  saveAction: GenericActionBarAction,
  enqueueSnackbar: any
) => {
  const functions = getFunctions(fireBaseApp, "europe-west1");
  const createUserCallable = httpsCallable(functions, "createUserCallable");

  const setCustomClaim = httpsCallable(functions, "setCustomClaim");

  const createUser = async () => {
    const pw =
      (Math.random() * 100000).toString(16) &&
      "-" &&
      (Math.random() * 100000).toString(16);

    const uid = await createUserCallable({
      displayName: state?.name,
      email: state?.email,
      //TODO hier was cleveres einbauen
      password: pw,
      emailVerified: false,
    });

    navigator.clipboard
      .writeText(pw)
      .then((e) => alert("PW pasted to clipboard"));

    let claim = {};
    //@ts-ignore
    claim[state?.Role?.id] = true;
    //@ts-ignore
    const uidData = uid?.data?.uid as unknown as string;
    setCustomClaim({ uid: uidData, claim: claim }).catch((e) =>
      enqueueSnackbar(e.message, { variant: "error" })
    );

    saveAction.action({
      ...state,
      uid: uidData,
      id: uidData,
    });
  };

  if (!state?.uid) {
    createUser().catch((e) => enqueueSnackbar(e.message, { variant: "error" }));
  } else {
    let claim = {};
    //@ts-ignore
    claim[state?.Role?.id] = true;
    setCustomClaim({ uid: state.uid, claim: claim }).catch((e) =>
      enqueueSnackbar(e.message, { variant: "error" })
    );
    saveAction.action(state);
  }
};

export const useGetUserUIEnhancer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const userActionBarEnhancer = (
    item: any,
    actions: GenericActionBarAction[]
  ) => {
    const saveAction = actions[0];
    const deleteAction = actions[1];
    return [
      {
        action: (state: any) => {
          saveUser(state, saveAction, enqueueSnackbar);
        },
        name: "Save ",
        icon: <Save />,
      },
      {
        action: (state: any) => {
          deleteUser(state, deleteAction, enqueueSnackbar);
        },
        name: "Delete ",
        icon: <Delete />,
      },
      {
        action: (state: any) => {
          const functions = getFunctions(fireBaseApp, "europe-west1");
          const getCustomClaims = httpsCallable(functions, "getCustomClaims");

          getCustomClaims({ uid: state.uid }).then((data) => {
            console.info(data);
          });
        },
        name: "Claims ",
        icon: <Info />,
      },
      {
        action: (state: any) => {
          const functions = getFunctions(fireBaseApp, "europe-west1");
          const makeUserKapionAdmin = httpsCallable(
            functions,
            "makeUserKapionAdmin"
          );

          makeUserKapionAdmin({ uid: state.uid })
            .then(() =>
              enqueueSnackbar("User promoted to Kapion Admin", {
                variant: "success",
              })
            )
            .catch((e) => {
              console.log(e);
              enqueueSnackbar(e.message, { variant: "error" });
            });
        },
        name: "Promote to KapionAdmin",
        icon: <AdminPanelSettings />,
      },
    ];
  };

  const userUIEnhancer = {
    enhanceActionBar: userActionBarEnhancer,
  } as UiEnhancements;

  return { key: "User", enhancer: userUIEnhancer };
};
