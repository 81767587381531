import { Divider, Menu, MenuItem, Switch } from "@mui/material";
import Typography from "@mui/material/Typography";
import { setThemeMode, themeMode } from "./appBarState";
import i18n from "i18next";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useTranslation } from "../custom-hooks/useTranslation";
import { ChangePasswordDialog } from "../UserManagement/ChangePasswordDialog";
import { getAuth, signOut } from "firebase/auth";
import { fireBaseApp } from "../firebase-config";
interface propsType {
  anchorEl: HTMLElement | null;
  menuId: string;
  isMenuOpen: boolean;
  handleMenuClose: () => void;
}

export function StyledMenu(props: propsType) {
  const dispatch = useDispatch();
  const [selectedTheme, setSelectedTheme] = useState<themeMode | string>();
  const user = useSelector((state: RootState) => state.user.user);
  const themeModeRedux = useSelector(
    (state: RootState) => state.appBar.themeMode
  );
  const storedTheme = localStorage.getItem("themeMode") as themeMode;
  const { t } = useTranslation();

  const [changePasswordOpen, setChangePasswordOpen] = useState(false);

  useEffect(() => {
    if (!storedTheme) {
      setSelectedTheme(themeModeRedux);
    } else {
      dispatch(setThemeMode(storedTheme));
      setSelectedTheme(storedTheme);
    }
  }, []);
  // @ts-ignore

  return (
    <Menu
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={props.menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={props.isMenuOpen}
      onClose={props.handleMenuClose}
    >
      <MenuItem>Name: {user?.displayName}</MenuItem>
      <MenuItem>Mail: {user?.email} </MenuItem>
      <MenuItem>
        Role:{" "}
        {user?.claims?.Admin
          ? "Admin"
          : user?.claims?.User
          ? "User"
          : "No Role"}
      </MenuItem>
      <Divider />
      <MenuItem onClick={props.handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={props.handleMenuClose}>My account</MenuItem>
      <MenuItem
        onClick={() => {
          props.handleMenuClose();
          setChangePasswordOpen(true);
        }}
      >
        {t("StyledMenu.changePassword")}
      </MenuItem>

      <MenuItem
        onClick={() => {
          const auth = getAuth(fireBaseApp);
          signOut(auth).then((e) => props.handleMenuClose());
        }}
      >
        {t("StyledMenu.SignOut")}
      </MenuItem>
      {changePasswordOpen && (
        <ChangePasswordDialog
          open={changePasswordOpen}
          onClose={() => {
            setChangePasswordOpen(false);
          }}
        />
      )}
    </Menu>
  );
}
