import { combineReducers, configureStore } from "@reduxjs/toolkit";
import mapReducer from "./components/map/mapState";
import { userReducer } from "./components/MaterialOnFire/login/userSate";
import { appBarReducer } from "./components/MaterialOnFire/app-bar/appBarState";
import genericValueHelpReducer from "./components/MaterialOnFire/GenericUIFields/GenericSelect/GenericValueHelpState";

import uiDefinitionsReducer from "./components/MaterialOnFire/UI-Builder/uiBuilderServices/uiDefinitionState";
import { maintenanceListReducer } from "./components/MaterialOnFire/GenericUIViews/GenericMaintenanceList/GenericMaintenanceListState";
import { UiDefinition } from "./components/MaterialOnFire/UI-Builder/uiBuilderServices/uiDefinition";
import {
  generateCRUDFirebaseServices,
  GenericFirebaseFields,
  getReadCachedItemsFunction,
} from "./components/MaterialOnFire/UI-Builder/uiBuilderServices/BaseFireBaseServices";
import {
  DynamicCrudReducerExport,
  getDynamicCRUDReducer,
} from "./components/MaterialOnFire/UI-Builder/uiBuilderServices/DynamicReducer";
import { dynamicUiReducer } from "./components/MaterialOnFire/DynamicUIFrameworkState";
import { translationReducer } from "./components/MaterialOnFire/translations/translationsState";

// ...

const staticReducers = {
  map: mapReducer,
  appBar: appBarReducer,
  dynamicUi: dynamicUiReducer,
  user: userReducer,
  genericValueHelp: genericValueHelpReducer,
  uiDefinitions: uiDefinitionsReducer,
  maintenanceList: maintenanceListReducer,
  translations: translationReducer,
};

const asyncReducers: any = {};

export const store = configureStore({
  //@ts-ignore
  reducer: staticReducers,
});

interface FireBaseFields {
  items: GenericFirebaseFields[];
  initialized: boolean;
}

export const addReducer = (definition: UiDefinition) => {
  if (!definition) return undefined;
  const readCacheFunction = getReadCachedItemsFunction<GenericFirebaseFields>(
    "/" + definition.model
  );

  const dynamicReducer: DynamicCrudReducerExport<
    GenericFirebaseFields,
    FireBaseFields
  > = getDynamicCRUDReducer<GenericFirebaseFields, FireBaseFields>(
    definition.model,
    { items: [], initialized: false },
    readCacheFunction
  );

  asyncReducers[definition.model] = dynamicReducer.reducer;

  const crud = generateCRUDFirebaseServices(
    "/" + definition.model,
    definition.model,
    dynamicReducer.slice.actions.add,
    dynamicReducer.slice.actions.delete
  );

  store.replaceReducer(
    //@ts-ignore
    combineReducers({ ...staticReducers, ...asyncReducers })
  );

  return {
    actions: dynamicReducer.slice.actions,
    crudService: crud,
    fetchAll: dynamicReducer.fetchAll,
  };
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
