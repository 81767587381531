import { MouseEventHandler, ReactNode, useEffect, useState } from "react";
import {
  GenericActionBar,
  GenericActionBarAction,
} from "../../GenericUIFields/GenericActionBar";
import {
  Button,
  Collapse,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  Stack,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import {
  ArrowCircleDown,
  ArrowCircleUp,
  ArrowDownward,
  ArrowUpward,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";

interface GenericNestedListInterface {
  list: any[];
  isSelected: (item: any) => boolean;
  listItemOnClick: (item: any) => (event: any) => void;
  getListItemVisualizer: (item: any) => ReactNode;
  getListItemActions: (listItem: any) => GenericActionBarAction[];
  getNestingCriteriaFromListItem: (listItem: any) => string;
  getNestingCriteriaVisualizer: (listItem: any) => ReactNode;
  getNestingCriteriaActions: (listItem: any) => GenericActionBarAction[];
}

export const GenericNestedList = (props: GenericNestedListInterface) => {
  const [nestedMap, setNestedMap] = useState<Map<string, any[]>>(
    new Map<string, any[]>()
  );

  const [openMap, setOpenMap] = useState<Map<string, boolean>>(
    new Map<string, boolean>()
  );

  const setOpenState = (key: string, state: boolean) => {
    const newOpenMap = new Map(openMap);
    if (newOpenMap.has(key)) {
      newOpenMap.set(key, state);
    } else {
      newOpenMap.set(key, true);
    }
    setOpenMap(newOpenMap);
  };

  useEffect(() => {
    const newMap = new Map<string, any[]>();

    for (let listItem of props.list) {
      let key = props.getNestingCriteriaFromListItem(listItem);
      if (newMap.has(key)) {
        let array = newMap.get(key);
        if (array) newMap.set(key, [...array, listItem]);
      } else {
        newMap.set(key, [listItem]);
      }
    }

    setNestedMap(newMap);
  }, [props.list, props.getNestingCriteriaFromListItem]);

  const Items = () => {
    const listItems: ReactNode[] = [];
    nestedMap.forEach((items, key) => {
      listItems.push(
        <>
          <ListItem
            key={key}
            onClick={() => {
              setOpenState(key, !openMap.get(key));
            }}
          >
            <Grid container sx={{ cursor: "pointer" }}>
              <Grid item md={2} lg={1}>
                {openMap.get(key) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </Grid>
              <Grid item md={1} lg={1}>
                <Badge badgeContent={items.length} color="secondary" />
              </Grid>
              <Grid item md={9} lg={10}>
                {props.getNestingCriteriaVisualizer(items[0])}
              </Grid>
            </Grid>
          </ListItem>
          <Divider />
          <Collapse in={openMap.get(key)} timeout={"auto"} unmountOnExit={true}>
            <List component="div" disablePadding>
              {items.map((item, index) => (
                <>
                  <ListItem
                    key={key + "_" + index}
                    selected={props.isSelected(item)}
                  >
                    <Grid container alignItems={"center"}>
                      <Grid item xs={10}>
                        <ListItemButton
                          onClick={props.listItemOnClick(item)}
                          selected={props.isSelected(item)}
                        >
                          {props.getListItemVisualizer(item)}
                        </ListItemButton>
                      </Grid>
                      <Grid item xs={1}>
                        <GenericActionBar
                          state={item}
                          actions={props.getListItemActions(item)}
                          threshold={0}
                        />
                      </Grid>
                      <Grid item xs={1} />
                    </Grid>
                  </ListItem>

                  <Divider />
                </>
              ))}
            </List>
          </Collapse>
        </>
      );
    });

    return <>{listItems}</>;
  };
  return (
    <List>
      <Items />
    </List>
  );
};
