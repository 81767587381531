import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { firebaseDB } from "../../../components/MaterialOnFire/firebase-config";

export interface TTNMessage {
  time: Date;
  type: "uplink" | "downlink";
  payload: string;
}

export const readTTNUplinkMessage = async (id: string) => {
  const loraNodes = query(
    collection(firebaseDB, "/ttnMessage/ttnMessage/uplink"),
    where("end_device_ids.device_id", "==", id),
    orderBy("received_at", "desc"),
    limit(10)
  );
  const uplinkMessage: TTNMessage[] = [];
  try {
    const snapshotUplinkMessage = await getDocs(loraNodes);
    snapshotUplinkMessage.forEach((doc) => {
      let data = doc.data();
      uplinkMessage.push({
        time: new Date(data?.received_at) || "",
        type: "uplink",
        payload: JSON.stringify(data?.uplink_message?.decoded_payload) || "",
      });
    });
  } catch (e) {
    console.log(e);
  }

  return uplinkMessage;
};

export const readTTNDownlinkkMessage = async (id: string) => {
  const loraNodes = query(
    collection(firebaseDB, "/ttnMessage/ttnMessage/downlinkSent"),
    where("end_device_ids.device_id", "==", id),
    orderBy("received_at", "desc"),
    limit(10)
  );
  const uplinkMessage: any[] = [];
  try {
    const snapshotUplinkMessage = await getDocs(loraNodes);
    snapshotUplinkMessage.forEach((doc) => {
      let data = doc.data();
      uplinkMessage.push({
        time: new Date(data?.received_at) || "",
        type: "downlink",
        payload: data?.downlink_sent?.decoded_payload?.bytes?.toString() || "",
      });
    });
  } catch (e) {
    console.log(e);
  }

  return uplinkMessage;
};
