import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import MapBasedDashboard from "../../map/MapBasedDashboard";
import LandingPageTile from "../landing-page/LandingPageTile";
import { UIBuilder } from "../UI-Builder/UIBuilder";

import { useGetNewTTNCount } from "../../custom-hooks/useGetNewTTNCount";

import { GenericUI } from "../GenericUI";
import { GenericActionBarAction } from "../GenericUIFields/GenericActionBar";
import { MapRounded, QuestionMark, Translate, Web } from "@mui/icons-material";
import { useGetIconList } from "./useGetIconList";

import { Route } from "react-router-dom";
import { Translator } from "../translations/Translator";
import { getUserRole, KapionUser, userHasPermission } from "../login/userSate";

export interface CustomRoute {
  route: string;
  name: string;
  component: ReactNode;
  landingPageTile: ReactNode;
  icon: ReactNode;
}

export interface UiEnhancements {
  enhanceActionBar: (
    item: any,
    defaultActionbar: GenericActionBarAction[]
  ) => GenericActionBarAction[];
  injectJSX?: React.ReactElement;
}

let appSpecificRoute: CustomRoute[] = [];

export const useGetRoutes = (uiEnhancers?: Map<string, UiEnhancements>) => {
  const uiDefinitions = useSelector(
    (state: RootState) => state.uiDefinitions.uiDefinitions
  );

  const user = useSelector((state: RootState) => state.user.user);

  const newTTNDeviceCount = useGetNewTTNCount();
  const landingPageOveride = {
    ViewTTNDeviceUI: newTTNDeviceCount,
  };

  const iconList = useGetIconList();

  const getRoutes = () => {
    const userRole = getUserRole(user);

    const routes: CustomRoute[] =
      userRole === "KapionAdmin"
        ? [
            {
              route: "/uiBuilderServices-builder/$",
              name: "uiBuilderServices-builder",
              component: <UIBuilder />,
              icon: <Web />,
              landingPageTile: (
                <LandingPageTile
                  name={"landingPage.uiBuilderServices-builder"}
                  link={"/uiBuilderServices-builder/$"}
                  Icon={"Web"}
                />
              ),
            },
            {
              route: "/translations",
              name: "translator",
              component: <Translator />,
              icon: <Translate />,
              landingPageTile: (
                <LandingPageTile
                  name={"translator"}
                  link={"/translations"}
                  Icon={"Translate"}
                />
              ),
            },
            ...appSpecificRoute,
          ]
        : [...appSpecificRoute];

    for (let definition of uiDefinitions) {
      let enhancer;
      if (uiEnhancers) {
        enhancer = uiEnhancers.get(definition.model);
      }

      const hasPermission = userHasPermission(userRole, definition?.Role?.id);

      if (hasPermission) {
        routes.push({
          route: definition.route || "",
          name: definition.name,
          //@ts-ignore
          component: (
            <GenericUI model={definition.model} uiEnhancer={enhancer} />
          ),
          icon: iconList.getIcon(definition.icon || ""),
          landingPageTile: (
            <LandingPageTile
              name={"landingPage." + definition.name}
              link={definition.route || ""}
              Icon={definition.icon}
              //@ts-ignore
              display={landingPageOveride[definition.name] || ""}
            />
          ),
        });
      }
    }

    return routes;
  };

  return {
    getRoutes: getRoutes,
    mapCustomRouteToRoute: (customRoutes: CustomRoute[]) => {
      return customRoutes.map((customRoute) => {
        return (
          <Route
            path={customRoute.route.replace("$", ":id")}
            element={customRoute.component}
          />
        );
      });
    },

    setAppSpecificRoutes: (customRoutes: CustomRoute[]) => {
      appSpecificRoute = customRoutes;
    },
  };
};
