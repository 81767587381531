import { UiEnhancements } from "../MaterialOnFire/custom-hooks/useGetRoutes";

import { useGetDiscoveredTTNNodesUIEnhancer } from "./uiEnhancers/useGetDiscoveredTTNNodesUIEnhancer";
import { useGetLoraNodesUIEnhancer } from "./uiEnhancers/useGetLoraNodesUIEnhancer";
import { FormatPaint } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useTranslation } from "../MaterialOnFire/custom-hooks/useTranslation";
import { useGetUserUIEnhancer } from "./uiEnhancers/useGetUserUIEnhancer";

export const useGetUiEnhancers = () => {
  const enhancers = new Map<string, UiEnhancements>();
  const discoveredTTNNodesEnhancer = useGetDiscoveredTTNNodesUIEnhancer();
  const loraNodeEnhancer = useGetLoraNodesUIEnhancer();
  const userEnhancer = useGetUserUIEnhancer();
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  enhancers.set(
    discoveredTTNNodesEnhancer.key,
    discoveredTTNNodesEnhancer.enhancer
  );
  enhancers.set(loraNodeEnhancer.key, loraNodeEnhancer.enhancer);
  enhancers.set(userEnhancer.key, userEnhancer.enhancer);

  enhancers.set("Theme", {
    enhanceActionBar: (item, defaultActionbar) => {
      return [
        ...defaultActionbar,
        {
          action: (newState: any) => {
            localStorage.setItem("preferredThemeId", newState?.id);
            snackbar.enqueueSnackbar(t("Theme.RefreshPageToShowTheme"), {
              variant: "info",
            });
          },
          name: "Set active",
          icon: <FormatPaint />,
        },
      ];
    },
  });

  return enhancers;
};
