import { Button, Grid, Paper, Switch } from "@mui/material";
import Map from "./Map";
import ItemDetails from "./details/ItemDetails";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSearchEnabled } from "../MaterialOnFire/app-bar/appBarState";
import { addMetersToGeoPoint } from "../../helper/geoPoint";
import { Lamp, setMultiple } from "../../model/lamps/lampState";
import { addLamp } from "../../model/lamps/lampFirebaseServices";
import { NodeOverview } from "./overview/NodeOverview";

export default () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSearchEnabled(true));
  }, []);

  return (
    <Grid container sx={{ height: "100%", width: "100%" }}>
      <Grid item xs={8}>
        <Map />
      </Grid>
      <Grid item xs component={Paper} sx={{ mx: 1 }}>
        <NodeOverview />
      </Grid>
    </Grid>
  );
};
